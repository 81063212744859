import { computed, Injectable, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AuthService } from '@rcg/auth';
import { GraphqlClientService } from '@rcg/graphql';
import { IPaging } from '@rcg/standalone';
import { gql } from 'apollo-angular';
import { DocumentNode } from 'graphql';
import {
  BehaviorSubject,
  catchError,
  combineLatest,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  Observable,
  of,
  switchMap,
  tap,
  throwError,
} from 'rxjs';
import { buildGqlQuery, QueryInput, QueryResult } from '../gql-builder';
import { FilterExpressions } from '../models';

export interface ListState {
  data: Record<string, unknown>[];
  loading: boolean;
  error: string | null;
  queryInput: QueryInput | null;
  paging: IPaging | null;
  search: string | null;
  count: number | null;
}

interface ListSubjects {
  search: string | null;
  paging: IPaging | null;
  queryInput: QueryInput | null;
  filter: FilterExpressions | null;
  refresh: boolean;
}

@Injectable()
export class RcgListService {
  private intialState = {
    data: [],
    loading: false,
    error: null,
    queryInput: null,
    paging: null,
    search: null,
    count: null,
  };

  private readonly _state = signal<ListState>(this.intialState);

  readonly state = computed(() => this._state());

  readonly count = computed(() => this.state().count);

  private readonly context = signal<Record<string, unknown>>({});

  private readonly listSubjects = new BehaviorSubject<ListSubjects>({
    search: null,
    paging: null,
    queryInput: null,
    filter: null,
    refresh: false,
  });

  private readonly defaultLimit = 15;

  constructor(private graphQlClient: GraphqlClientService, private authService: AuthService) {
    this.context.set({
      search: '',
      user: this.authService.user(),
      tenant: this.authService.tenant(),
      tenantId: this.authService.tenant()?.id,
      hasuraUserId: this.authService.user()?.id,
      organizationId: this.authService.tenant()?.organization?.id,
      organizationsShareType: this.authService.tenant()?.organizationShareType,
    });
    this.initDataFetching();
  }

  loadData(queryInput: QueryInput, filters?: FilterExpressions | null, context?: Record<string, unknown>) {
    const currentQueryInput = this.listSubjects.value.queryInput;
    const isQueryChanged = JSON.stringify(currentQueryInput) !== JSON.stringify(queryInput);

    if (isQueryChanged) {
      this._state.update(() => this.intialState);
    }

    this._state.update((s) => ({ ...s, loading: true }));
    this.context.set({ ...this.context(), ...context });
    this.updateListSubjects({
      queryInput,
      filter: filters ?? null,
      paging: {
        limit: queryInput.variables?.limit?.value ?? this.defaultLimit,
        offset: 0,
        hasMore: true,
        pageLimit: queryInput.variables?.limit?.value ?? this.defaultLimit,
      },
    });
  }

  loadMore(paging: IPaging) {
    if (!paging || !paging.hasMore) {
      return;
    }
    this.updateListSubjects({ paging });
  }

  refresh() {
    this.updateListSubjects({ refresh: !this.listSubjects.value.refresh });
  }

  search(search: string) {
    this.updateListSubjects({ search });
  }

  clear() {
    this._state.set(this.intialState);
    this.updateListSubjects({ queryInput: null, paging: null, filter: null });
  }

  private initDataFetching() {
    this.listSubjects
      .pipe(
        filter(({ queryInput }) => !!queryInput?.operation),
        distinctUntilChanged((prev, curr) => JSON.stringify(prev) === JSON.stringify(curr)),
        debounceTime(300),
        tap((s) => {
          this._state.update((state) => ({ ...state, error: null, loading: true }));
          this.context.set({
            ...this.context(),
            search: s.search ?? '',
          });
        }),
        switchMap(({ paging, queryInput, filter }) => {
          if (!queryInput) return [];
          const updatedQueryInput = {
            ...queryInput,
            variables: {
              ...queryInput.variables,
              limit: { ...queryInput.variables?.limit, value: paging?.limit || this.defaultLimit },
              offset: { ...queryInput.variables?.offset, value: paging?.offset || 0 },
            },
          };

          const queryResult = buildGqlQuery(updatedQueryInput, this.context(), filter || undefined);
          return this.fetchData(queryResult).pipe(
            map((result) => ({ result, paging })),
            catchError((error) => {
              return of({ error: error instanceof Error ? error.message : `${error}` });
            }),
          );
        }),
        takeUntilDestroyed(),
      )
      .subscribe({
        next: (response) => {
          if ('error' in response) {
            console.error('Error fetching list data:', response.error);
            this._state.update((s) => ({
              ...s,
              error: response.error,
              loading: false,
            }));
          } else {
            this._state.update((s) => ({
              ...s,
              data: response.result.data,
              count: response.result.count,
              error: null,
              loading: false,
              paging: response.paging,
            }));
          }
          this._state.update((s) => ({ ...s, loading: false }));
        },
        error: (err) => {
          console.error('Error fetching list data:', err instanceof Error ? err.message : err);
          this._state.update((s) => ({
            ...s,
            error: err.message,
            loading: false,
          }));
        },
      });
  }

  private updateListSubjects(update: Partial<ListSubjects>) {
    this.listSubjects.next({ ...this.listSubjects.value, ...update });
  }

  private fetchData(queryResult: QueryResult): Observable<{ data: Record<string, unknown>[]; count: number | null }> {
    try {
      const query: DocumentNode = gql`
        ${queryResult.query.toString().trim()}
      `;
      const variables = queryResult.variables;

      const gqlFn = queryResult.type === 'hasura-query' ? this.graphQlClient.query : this.graphQlClient.subscribe;
      const callableGqlFn = <T>(...args: Parameters<typeof gqlFn<T>>) => {
        const f = gqlFn<T>;
        return f.call(this.graphQlClient, ...args);
      };

      if (queryResult.type !== 'hasura-subscription' || !queryResult.subscriptionCount) {
        return callableGqlFn<{ data: Record<string, unknown>[]; aggregate: { aggregate: { count: number } } }>({
          query,
          variables,
        }).pipe(
          map((result) => ({
            data: result.data ?? [],
            count: result.aggregate?.aggregate?.count ?? null,
          })),
        );
      } else {
        const dataSubscription = callableGqlFn<{ data: Record<string, unknown>[] }>({ query, variables });
        const countSubscription = callableGqlFn<{ aggregate: { aggregate: { count: number } } }>({
          query: gql(`${queryResult.subscriptionCount.query!}`),
          variables: queryResult.subscriptionCount.variables,
        });

        return combineLatest([dataSubscription, countSubscription]).pipe(
          map(([dataResult, countResult]) => ({
            data: dataResult.data ?? [],
            count: countResult.aggregate?.aggregate?.count ?? null,
          })),
        );
      }
    } catch (error) {
      return throwError(() => new Error(`GraphQL ${queryResult.type} failed: ${error instanceof Error ? error.message : error}`));
    }
  }
}
