import { GraphQLConnectionType, OrderByExpression, WhereExpression } from '@npm-libs/ng-templater';
import { query as gqlQuery, mutation, subscription } from 'gql-query-builder';
import IQueryBuilderOptions from 'gql-query-builder/build/IQueryBuilderOptions';
import VariableOptions from 'gql-query-builder/build/VariableOptions';
import { FilterExpressions } from '../models';
import { buildOrderByExpressions, buildWhereExpressions } from './expressions';
import { GqlQLQuery, GqlVariables, QueryInput, QueryResult } from './types';

export const buildGqlQuery = (queryInput: QueryInput, context: Record<string, unknown>, filters?: FilterExpressions): QueryResult => {
  const { dataVariables, countVariables } = buildVariables(queryInput, context, filters);
  const options = buildOptions(queryInput, dataVariables, countVariables);
  const result = executeQuery(queryInput.type, options);
  return {
    ...result,
    type: queryInput.type,
  };
};

const buildVariables = (
  queryInput: QueryInput,
  context: Record<string, unknown>,
  filters?: FilterExpressions,
): {
  dataVariables: Record<string, VariableOptions>;
  countVariables?: Record<string, VariableOptions>;
} => {
  const baseVariables = queryInput.variables ?? {};
  const dataVariables = resolveVariables(baseVariables, context);
  resolveArgsVariable(dataVariables, context);
  buildWhereVariable(queryInput, dataVariables, context, filters);
  buildOrderByVariable(queryInput, dataVariables, filters);
  buildDistinctVariable(queryInput, dataVariables);

  let countVariables: Record<string, VariableOptions> | undefined;

  if (queryInput.count && queryInput.type !== 'hasura-mutation') {
    const countRelevantVariables = extractCountRelevantVariables(baseVariables);
    countVariables = resolveVariables(countRelevantVariables, context);
    resolveArgsVariable(countVariables, context);
    buildWhereVariable(queryInput, countVariables, context, filters);
    buildDistinctVariable(queryInput, countVariables);
  }

  return { dataVariables, countVariables };
};

const buildOptions = (
  queryInput: QueryInput,
  dataVariables: Record<string, VariableOptions>,
  countVariables?: Record<string, VariableOptions> | undefined,
): IQueryBuilderOptions[] => {
  const options: IQueryBuilderOptions[] = [
    {
      operation: {
        name: queryInput.operation,
        alias: queryInput.alias ?? 'data',
      },
      fields: queryInput.fields ?? ['__typename'],
      variables: dataVariables,
    },
  ];

  if (countVariables && queryInput.count && queryInput.type !== 'hasura-mutation') {
    options.push({
      operation: {
        name: `${queryInput.subOperationBase ?? queryInput.operation}_aggregate`,
        alias: 'aggregate',
      },
      variables: countVariables,
      fields: [{ aggregate: ['count'] }],
    });
  }

  return options;
};

const executeQuery = (type: GraphQLConnectionType, options: IQueryBuilderOptions[]): GqlQLQuery => {
  switch (type) {
    case 'hasura-query':
      return gqlQuery(options);
    case 'hasura-subscription': {
      const dataSubscription = subscription([options[0]]);
      const countSubscription = options[1] ? subscription([options[1]]) : undefined;
      return {
        query: dataSubscription.query,
        variables: dataSubscription.variables,
        subscriptionCount: countSubscription
          ? {
              query: countSubscription?.query,
              variables: countSubscription?.variables,
            }
          : undefined,
      };
    }
    case 'hasura-mutation':
      return mutation(options[0]);
    default:
      throw new Error(`Invalid GraphQL type: ${type}`);
  }
};

const resolveVariables = (variables: GqlVariables, context: Record<string, unknown>): GqlVariables => {
  return Object.fromEntries(
    Object.entries(variables).map(([key, varOption]) => [
      key,
      {
        ...varOption,
        value: resolveValue(varOption.value, context),
      },
    ]),
  );
};

const buildWhereVariable = (
  queryInput: QueryInput,
  variables: Record<string, VariableOptions>,
  context: Record<string, unknown>,
  filters?: FilterExpressions,
): void => {
  const defaultWhere = queryInput.variables?.where
    ? queryInput.variables.where.value.map((expression: WhereExpression) => resolveWhereExpression(expression, context))
    : [];

  const combinedWhere = combineWhereExpressions(defaultWhere, filters?.whereExpressions);

  variables.where = {
    value: buildWhereExpressions(combinedWhere),
    type: queryInput.variables?.where?.type || `${queryInput.subOperationBase ?? queryInput.operation}_bool_exp`,
  };
};

const buildOrderByVariable = (queryInput: QueryInput, variables: Record<string, VariableOptions>, filters?: FilterExpressions): void => {
  const defaultOrderBy = queryInput.variables?.order_by?.value || [];
  let combinedOrderBy: OrderByExpression[] = [...defaultOrderBy];

  if (filters?.orderByExpressions && filters.orderByExpressions.length > 0) {
    combinedOrderBy = [...filters.orderByExpressions];
  }

  if (combinedOrderBy.length > 0) {
    variables.order_by = {
      value: buildOrderByExpressions(combinedOrderBy),
      type: queryInput.variables?.order_by?.type || `[${queryInput.subOperationBase ?? queryInput.operation}_order_by!]`,
    };
  }
};

const buildDistinctVariable = (queryInput: QueryInput, variables: Record<string, VariableOptions>): void => {
  const distinct = queryInput.distinct?.filter((d) => !d.includes('.'));
  if (distinct?.length) {
    variables.distinct_on = {
      value: distinct,
      type: `[${queryInput.subOperationBase ?? queryInput.operation}_select_column!]`,
    };
  }
};

const combineWhereExpressions = (defaultWhere: WhereExpression[], filterExpressions?: WhereExpression[]): WhereExpression[] => {
  if (!filterExpressions) return defaultWhere;

  if (defaultWhere.length > 0 && 'predicates' in defaultWhere[0]) {
    if (defaultWhere.length > 1) {
      throw new Error('Invalid default where. When predicates _and can have only one item start with _and predicate');
    }
    return [
      {
        condition: 'and',
        predicates: [...defaultWhere[0].predicates, ...filterExpressions],
      },
    ];
  }

  return [...defaultWhere, ...filterExpressions];
};

const resolveValue = (value: unknown, context: Record<string, unknown>): unknown => {
  if (typeof value === 'string' && value.startsWith('$')) {
    const contextKey = value.slice(1);
    return context[contextKey] ?? value;
  } else if (typeof value === 'object' && value !== null) {
    return Array.isArray(value)
      ? value.map((item) => resolveValue(item, context))
      : Object.fromEntries(Object.entries(value).map(([k, v]) => [k, resolveValue(v, context)]));
  }
  return value;
};
const resolveArgsVariable = (variables: Record<string, VariableOptions>, context: Record<string, unknown>): void => {
  if (variables.args && typeof variables.args.value === 'object') {
    variables.args = {
      ...variables.args,
      value: resolveValue(variables.args.value, context),
    };
  }
};

const resolveWhereExpression = (expression: WhereExpression, context: Record<string, unknown>): WhereExpression => {
  if ('value' in expression) {
    return {
      ...expression,
      value: resolveValue(expression.value, context),
    };
  } else if ('predicates' in expression) {
    return {
      ...expression,
      predicates: expression.predicates.map((pred) => resolveWhereExpression(pred, context)),
    };
  }
  return expression;
};

const extractCountRelevantVariables = (variables: GqlVariables): GqlVariables => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { limit, offset, order_by, ...countRelevantVariables } = variables;
  return countRelevantVariables;
};
